.admin-active .dropdown-toggle {
  border: 2px solid #007bff;
  /* Blue border */
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  /* Light blue background */
  font-weight: bold;
}

.nav-link.active {
  border: 2px solid #007bff;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  /* Make background transparent */
  font-weight: bold;
}

.navbar {
  background: linear-gradient(to bottom, #F6C01E, #ED5D25);
  color: #fff;
  /* Optional: Adjust text color for better contrast */
  border: none;
  /* Remove border if any */
}

/* Adjust the spacing, font size, etc. of the centered section name */
.navbar .text-center h5 {
  font-size: 1.25rem;
  margin: 0;
}

.section-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  /* Adjust the color as needed */
  z-index: 1;
  /* Ensure it stays on top */
}